import { CLEAR_ERRORS, GET_SUBSCRIPTION_FAIL, GET_SUBSCRIPTION_REQUEST, GET_SUBSCRIPTION_SUCCESS } from "../constants/subscriptionConstants";

export const subscriptionReducer = (state = { subscriptionDetail: []}, action)=>{
    switch (action.type){
        case GET_SUBSCRIPTION_REQUEST:
            return{
                subscriptionDetailLoading: true,
                subscriptionDetail: [],
            };
        case GET_SUBSCRIPTION_SUCCESS: 
            return {
                subscriptionDetailLoading: false,
                subscriptionDetail: action.payload
            }
        case GET_SUBSCRIPTION_FAIL:
            return {
                subscriptionDetailLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}


export const subscriptionDateReducer = (state = { subscriptionDateDetail: []}, action)=>{
    switch (action.type){
        case GET_SUBSCRIPTION_REQUEST:
            return{
                subscriptionDateDetailLoading: true,
                subscriptionDateDetail: [],
            };
        case GET_SUBSCRIPTION_SUCCESS: 
            return {
                subscriptionDateDetailLoading: false,
                subscriptionDateDetail: action.payload
            }
        case GET_SUBSCRIPTION_FAIL:
            return {
                subscriptionDateDetailLoading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
            default:
            return state;
            
    }
}