import axios from "axios";

const axiosInstance = axios.create({

  // Base URL Or QE Gold
  // baseURL: "https://swsqe10.softwisesol.com",
// 
  // Base URL Or POS Demo
  // baseURL: "https://swsposdemo01.softwisesol.com",

  // Base URL Or QE Prime
  baseURL: 'https://swsqe11.softwisesol.com',

  
  // baseURL: 'http://localhost:4500',
  withCredentials: true,
});
export default axiosInstance;