export const POST_SALE_PRODUCT_TO_FISCAL_REQUEST = "POST_SALE_PRODUCT_TO_FISCAL_REQUEST"
export const POST_SALE_PRODUCT_TO_FISCAL_SUCCESS = "POST_SALE_PRODUCT_TO_FISCAL_SUCCESS"
export const POST_SALE_PRODUCT_TO_FISCAL_FAIL = "POST_SALE_PRODUCT_TO_FISCAL_FAIL"


export const POST_SALE_FBR_PRODUCT_REQUEST = "POST_SALE_FBR_PRODUCT_REQUEST"
export const POST_SALE_FBR_PRODUCT_SUCCESS = "POST_SALE_FBR_PRODUCT_SUCCESS"
export const POST_SALE_FBR_PRODUCT_FAIL = "POST_SALE_FBR_PRODUCT_FAIL"

export const POST_SALE_PRODUCT_REQUEST = "POST_SALE_PRODUCT_REQUEST"
export const POST_SALE_PRODUCT_SUCCESS = "POST_SALE_PRODUCT_SUCCESS"
export const POST_SALE_PRODUCT_FAIL = "POST_SALE_PRODUCT_FAIL"

export const GET_SALE_PRODUCT_REQUEST = "GET_SALE_PRODUCT_REQUEST"
export const GET_SALE_PRODUCT_SUCCESS = "GET_SALE_PRODUCT_SUCCESS"
export const GET_SALE_PRODUCT_FAIL = "GET_SALE_PRODUCT_FAIL"

export const GET_SALE_CONSOLIDATED_FOR_SHOPS_REQUEST = "GET_SALE_CONSOLIDATED_FOR_SHOPS_REQUEST"
export const GET_SALE_CONSOLIDATED_FOR_SHOPS_SUCCESS = "GET_SALE_CONSOLIDATED_FOR_SHOPS_SUCCESS"
export const GET_SALE_CONSOLIDATED_FOR_SHOPS_FAIL = "GET_SALE_CONSOLIDATED_FOR_SHOPS_FAIL"

export const GET_SALE_CONSOLIDATED_FOR_SHOPS_ON_DATE_REQUEST = "GET_SALE_CONSOLIDATED_FOR_SHOPS_ON_DATE_REQUEST"
export const GET_SALE_CONSOLIDATED_FOR_SHOPS_ON_DATE_SUCCESS = "GET_SALE_CONSOLIDATED_FOR_SHOPS_ON_DATE_SUCCESS"
export const GET_SALE_CONSOLIDATED_FOR_SHOPS_ON_DATE_FAIL = "GET_SALE_CONSOLIDATED_FOR_SHOPS_ON_DATE_FAIL"

export const GET_SALE_CONSOLIDATED_FOR_SPECIFIC_SHOP_REQUEST = "GET_SALE_CONSOLIDATED_FOR_SPECIFIC_SHOP_REQUEST"
export const GET_SALE_CONSOLIDATED_FOR_SPECIFIC_SHOP_SUCCESS = "GET_SALE_CONSOLIDATED_FOR_SPECIFIC_SHOP_SUCCESS"
export const GET_SALE_CONSOLIDATED_FOR_SPECIFIC_SHOP_FAIL = "GET_SALE_CONSOLIDATED_FOR_SPECIFIC_SHOP_FAIL"

export const GET_SALE_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_REQUEST = "GET_SALE_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_REQUEST"
export const GET_SALE_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_SUCCESS = "GET_SALE_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_SUCCESS"
export const GET_SALE_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_FAIL = "GET_SALE_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_FAIL"


export const GET_SALE_CONSOLIDATED_PROFIT_FOR_SHOPS_REQUEST = "GET_SALE_PROFIT_CONSOLIDATED_FOR_SHOPS_REQUEST"
export const GET_SALE_CONSOLIDATED_PROFIT_FOR_SHOPS_SUCCESS = "GET_SALE_PROFIT_CONSOLIDATED_FOR_SHOPS_SUCCESS"
export const GET_SALE_CONSOLIDATED_PROFIT_FOR_SHOPS_FAIL = "GET_SALE_PROFIT_CONSOLIDATED_FOR_SHOPS_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"